import ReactGA from "react-ga4";

export const SERVER = process.env.REACT_APP_SERVER;
// export const GOOGLE_ANALYTICS = "" //process.env.REACT_APP_GOOGLE;
export const APP_DOMAIN = window.location.host === 'localhost:3000' ? 'app.barti.nl' : window.location.host;


// console.log('GOOGLE_ANALYTICS: ', GOOGLE_ANALYTICS);

// ReactGA.initialize(GOOGLE_ANALYTICS, { debug: false });
// ReactGA.pageview(window.location.pathname);
// console.log();

export const GApageView = (page) => {
    // ReactGA.send(page);
    ReactGA.send({ hitType: "pageview", page: page });
};

export const GAmodalView = (modal) => {
    ReactGA.modalview(modal);
};

export const GAevent = (category, action) => {
    ReactGA.event({
        category: category,
        action: action
    });
};


// eslint-disable-next-line no-extend-native
Date.prototype.addYears = function (years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years)
    return date;
};


// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (hours) {
    var date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes) {
    var date = new Date(this.valueOf());
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};
