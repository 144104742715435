import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";
import PageHeroHome from '../../components/hero/hero.home';
// import PageFooter from '../../components/footer';
// import { Section1, Section2, Section4, Section5, SectionNews, SectionAddress } from './sections';
import { ModalContact } from '../../components/modals';

const HomePage = ({
    history,
    ...props
}) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    // const { GApageView } = props;
    // GApageView(window.location.pathname);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${process.env.REACT_APP_TITLE_NAME}/`}</title>
                {/* <meta name="description" content={`${process.env.REACT_APP_DESCRIPTION}/`} />
                <meta property="og:title" content={`${process.env.REACT_APP_TITLE_NAME}/`} />
                <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}/`} /> */}
                {/* <meta name="keywords" content={page.seo?.keywords || page.localizations[0].seo.keywords} /> */}
                {/* <meta property="og:image" content={page.seo?.image?.url || page.localizations[0].seo?.image?.url} /> */}
                {/* <link rel="icon" type="image/png" href={page.seo?.image?.url || page.localizations[0].seo?.image?.url} sizes="16x16" /> */}
            </Helmet>

            <PageHeroHome isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} />
            {/* <Section1 />
            <Section2 />
            <SectionNews {...props} /> */}
            {/* <Section3 /> */}
            {/* <Section4 isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} />
            <Section5 isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} /> */}

            {/* <SectionAddress /> */}
            {/* <PageFooter isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} /> */}
            {isContactModalOpen && (<ModalContact isModalOpen={isContactModalOpen} modalOpener={setIsContactModalOpen} />)}
        </React.Fragment>
    );
};

export default HomePage;
