import React from 'react';
// import { FormattedMessage } from "react-intl";
// import { Link } from 'react-router-dom';
import HeaderMenu from './header.menu';
// import { Menu } from 'antd';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

import logo from '../../assets/images/barti-logo-header.svg'

const PageHeroHome = ({
    setIsContactModalOpen,
    isContactModalOpen = false
}) => {
    // const [selectedTabs, setSelectedTabs] = useState({ tab: 'freelancer' });

    // const selectTab = (tab) => {
    //     setSelectedTabs({ tab: tab });
    // };

    return (
        <React.Fragment>
            <HeaderMenu isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} />
            <section className="header">
                <div className="logo-box">
                    <div className="content">
                        <div className="logo">
                            <img src={logo} alt="BARTI - voor en door ICT'ers"></img>
                        </div>
                        <div className="titles">
                            <div className="main">Embrace your ambition</div>
                            {/* <div className="sub"><FormattedMessage id="pagehero-tag-line" /></div> */}

                        </div>
                    </div>
                </div>



            </section>
        </React.Fragment>


    );
};

export default PageHeroHome;