import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Menu } from 'antd';


const HeaderMenu = ({
    setIsContactModalOpen,
    isContactModalOpen = false
}) => {

    const menuItems = [
        {
            label: <Link to="/">Home</Link>,
            key: "/home"
        },
        {
            label: <FormattedMessage id="contact" />,
            key: "/contact",
            onClick: () => { setIsContactModalOpen(true) }
        },
        {
            label: <a href="https://www.linkedin.com/company/barti-bv" rel="noreferrer" target="_blank">LinkedIN</a>,
            key: "/LinkedIN"
        },
        {
            label: <a href={process.env.REACT_APP_APP_URL} ><FormattedMessage id="login" /></a>,
            key: "/login"
        },
    ];

    return (
        <React.Fragment>

            <div className="header-menu">
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['home']}
                    items={menuItems} />

            </div>
        </React.Fragment>
    );

};

export default HeaderMenu;