import React from "react";
import { FormattedMessage } from "react-intl";

const InputMessage = ({
    fade = false,
    message,
    className,
}) => {

    return (
        <React.Fragment>
            {message && (
                <span className={className}>
                    <FormattedMessage id={message}></FormattedMessage>
                </span>
            )}
        </React.Fragment>
    );
};

export default InputMessage;
