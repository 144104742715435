import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from "react-helmet-async";
import * as api from '../services/api';

import { GApageView } from '../config';
import { detectBrowserlanguage } from '../services/language';

import NotFound from '../components/Notfound';
import { HomePage } from '../containers';
// import JobsListPage from '../containers/jobs/job.list.page';
// import JobDetailPage from '../containers/jobs/job.detail.page';
// import NewsPage from '../containers/news';
// import NewsDetailView from '../containers/news/views/news.detail.view';
// import PageIndex from '../containers/pages/page.index';
// import AboutPage from '../containers/about/index';

import messages_nl from "../translations/nl.json";
import messages_en from "../translations/en.json";

import 'antd/dist/antd.min.css';
import '../assets/styles/animate.min.css';
import '../assets/styles/style.css';
import '../assets/styles/news.style.css';

const Routers = ({ ...props }) => {

    const [LanguageObject, setLanguageObject] = useState({});
    const [loading, setLoading] = useState(true);
    const additionalProps = { GApageView };

    useEffect(() => {
        setLanguageObject({
            messages: { en: messages_en, nl: messages_nl },
            language: detectBrowserlanguage()
        }, setLoading(false));
    }, [setLanguageObject, setLoading]);

    // wake-up lambda API
    useEffect(() => {
        api.get('/assignment_public/list').then(response => { });
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HelmetProvider>
            {loading ? (<p> loading language settings</p >) : (
                <>
                    <IntlProvider locale={LanguageObject.language} messages={LanguageObject.messages[LanguageObject.language]}>
                        <BrowserRouter>
                            <ScrollToTop />
                            <Routes>
                                <Route path="/" exact element={<HomePage {...additionalProps} />} />
                                <Route path="/home" exact element={<HomePage {...additionalProps} />} />

                                {/* <Route path="/ict-vacatures" exact element={<JobsListPage {...additionalProps} />} />
                                <Route path="/ict-vacature/:slug" exact element={<JobDetailPage {...additionalProps} />} />

                                <Route path="/over-barti" exact element={<AboutPage {...additionalProps} />} />

                                <Route path="/nieuws" exact element={<NewsPage {...additionalProps} />} />
                                <Route path="/nieuws/:slug" exact element={<NewsDetailView {...additionalProps} />} />

                                <Route path="/ict/:slug" exact element={<PageIndex {...additionalProps} />} />
 */}
                                <Route path="/*" element={<NotFound {...additionalProps} />} />
                            </Routes>
                        </BrowserRouter>
                    </IntlProvider>
                </>
            )}
        </HelmetProvider>
    );
};
export default Routers;

function ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // return <>{props.children}</>;
    return null;
}