import React, { useState } from "react";
import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/barti-logo-header.svg'
import HeaderMenu from '../../components/hero/header.menu';
import { ModalContact } from '../../components/modals';

const NotFound = ({ ...props }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const { GApageView } = props;
  GApageView(window.location.pathname);

  return (
    <React.Fragment>
      <HeaderMenu isContactModalOpen={isContactModalOpen} setIsContactModalOpen={setIsContactModalOpen} />
      <section className="header" style={{ minHeight: "300px" }}>
        <div className="logo-box logo-box-not-found">
          <div className="content">
            <div className="logo">
              <img src={logo} alt="BARTI" />
            </div>
            <div className="titles">
              <div className="main">Embrace your ambition</div>
            </div>
          </div>
        </div>
      </section>

      <Result
        status="404"
        title="404"
        subTitle="Sorry, this page does not exist."
        style={{ background: '#f2f2f2' }}
        extra={<NavLink to="/"><Button type="primary">Back to Home</Button></NavLink>}
      />
      {isContactModalOpen && (<ModalContact isModalOpen={isContactModalOpen} modalOpener={setIsContactModalOpen} />)}
    </React.Fragment>

  );
};

export default NotFound;