import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { Modal } from 'antd';
// import { CloseOutlined, EditOutlined, PlusSquareOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import { InputMessage } from '../../components/helpers';
import { SendContactForm } from '../../services/contact';
import { faPaperPlane, faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalContact = ({
    isModalOpen,
    modalOpener,
    ...prop
}) => {
    const [formHasSend, setFormHasSend] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(faPaperPlane);

    const { register, handleSubmit, errors } = useForm(
        { mode: "onBlur" }, // set the field validation mode
    );

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setButtonIcon(faSpinner);

        SendContactForm(data).then(response => {
            if (response.status) {
                setFormHasSend(true);
            }

        });
    };

    return (


        <Modal
            visible={isModalOpen}
            closable={false}
            // title={<><EditOutlined /> <FormattedMessage id="contactmodal-header" /></>}
            // onCancel={() => isModalOpen(false)}
            width={750}
            bodyStyle={{ padding: "0px" }}
            footer={[
                //     <Button key="1" onClick={() => isModalOpen(false)}><CloseOutlined /> <FormattedMessage id="cancel" /></Button >,
                //     <Button key="2"
                //         className="ant-btn-success"
                //         loading={buttonDisabled}
                //         onClick={handleSubmit(onSubmit)}>
                //         {!buttonDisabled && (<PlusSquareOutlined />)} <FormattedMessage id="add" /></Button>,
            ]}
        >

            <div className="close" onClick={() => { modalOpener(false) }}></div>
            <div className="overlay-head">
                <div><FormattedMessage id="contactmodal-header" /></div>
            </div>


            {/* <Modal.Body className="overlay-content-wrapper"> */}
            {formHasSend ? (
                <div className="overlay-body form-send pt-xl-5 pt-2">
                    <div>
                        <h1 className="h1"><FormattedMessage id="contactmodal-send-header" /></h1>
                        <p><FormattedMessage id="contactmodal-send-title" /></p>
                    </div>
                    <div className="text-success pt-5">
                        <FontAwesomeIcon className="fa-5x" icon={faCheckCircle} />
                    </div>
                </div>
            ) : (
                <form className="contact-form form">
                    <div className="overlay-body">
                        <div className="txt">
                            <FormattedMessage id="contactmodal-info" />
                        </div>
                        <div className="fields pt-2">
                            {/* <div className="field w100">
                                        <FormattedMessage id="field-company">{FieldName => (
                                            <input
                                                ref={register({
                                                    required: { value: true, message: "inputfield-FieldIsRequired" },
                                                })}
                                                type="text" name="companyname" placeholder={FieldName} required />
                                        )}
                                        </FormattedMessage>
                                        <InputMessage className="text-danger" fade={true} message={errors.companyname?.message}></InputMessage>
                                    </div> */}

                            <div className="field w50">
                                <FormattedMessage id="field-firstname">
                                    {FieldName => (
                                        <input
                                            ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })}
                                            type="text" name="firstname" placeholder={FieldName} required />
                                    )}
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.firstname?.message}></InputMessage>
                            </div>

                            <div className="field w50">
                                <FormattedMessage id="field-lastname">
                                    {FieldName => (
                                        <input
                                            ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })}
                                            type="text" name="lastname" placeholder={FieldName} required />
                                    )}
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.lastname?.message}></InputMessage>
                            </div>

                            <div className="field w50">
                                <FormattedMessage id="field-email">
                                    {FieldName => (
                                        <input
                                            ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                    message: "EmailAddressNotValid",
                                                },
                                            })}
                                            type="email" name="email" placeholder={FieldName} required />
                                    )}
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.email?.message}></InputMessage>
                            </div>

                            <div className="field w50">
                                <FormattedMessage id="field-phone">
                                    {FieldName => (
                                        <input
                                            ref={register({
                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                            })}
                                            type="text" name="phone" placeholder={FieldName} required />
                                    )}
                                </FormattedMessage>
                                <InputMessage className="text-danger" fade={true} message={errors.phone?.message}></InputMessage>
                            </div>

                            <div className="field w100">
                                <FormattedMessage id="field-your-question">{FieldName => (
                                    <textarea
                                        ref={register({
                                            required: { value: true, message: "inputfield-FieldIsRequired" },
                                        })}
                                        type="text" name="comments" placeholder={FieldName} required />
                                )}
                                </FormattedMessage>
                                <InputMessage className="text-danger" message={errors.comments?.message}></InputMessage>
                            </div>

                        </div>
                        <div
                            disabled={buttonDisabled}
                            onClick={handleSubmit(onSubmit)}
                            className={`${buttonDisabled ? 'submit disabled' : 'submit'} mt-2`}>Let's go! <FontAwesomeIcon icon={buttonIcon} className={buttonDisabled ? 'ml-2 disabled fa-spin' : 'ml-2'} /></div>
                    </div>
                </form>
            )}

            {/* </Modal.Body> */}
        </Modal>

    );
};

export default ModalContact;