import * as api from '../api';

export const SendContactForm = (data) => {
    return new Promise((resolve, reject) => {
        api
            .post('/mailler/contact', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.error('error in SendContactForm: ', error);
                reject(error);
            })
    });
};